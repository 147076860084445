/* Tailwind */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Vendor css */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';

.h1 {
    @apply text-4xl font-bold mb-5;
}

.h2 {
    @apply text-3xl font-bold mb-4;
}

.h3 {
    @apply text-2xl font-bold mb-4;
}

.paragraph-text {
    @apply text-lg my-4;
}